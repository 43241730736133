import _ from 'lodash';

import { fetchArchivedMessages } from '../../State/actions/chats';
import store from '../../State/configureStore';
import Config from '../../Utils/Config';
import { sendSocketReconnectionEvent } from '../BotsService';
import { queue } from '../gRPC/Generated/QueueService';
import { getSelectedConversation, putInStorage } from '../StorageService';

import RPC from './RPC';

const io = require('socket.io-client');

const baseURL = Config.gRPCURL;

export default class QueueServiceClient {
    static socket = null;

    static isSocketConnected() {
      return QueueServiceClient.socket?.connected;
    }

    static setupQueueMessageStream(receiveIncoming) {
      const userData = store.getState().user ? store.getState().user : null;
      const email = userData?.user?.emailAddress;
      const sessionId = _.get(userData, 'auth.sessionId', null);

      const socketParams = {
        path: '/grpc/clientConn',
        // Config.envName == "development" ? "/grpc/clientConn" : "/clientConn",
        transports: ['polling', 'websocket'],
        transportOptions: {
          polling: { extraHeaders: { sessionId: _.get(userData, 'auth.sessionId', null) } },
          websocket: { extraHeaders: { sessionId: _.get(userData, 'auth.sessionId', null) } },
        },
        reconnection: Boolean(sessionId),
        withCredentials: true,
        autoConnect: false,
      };

      QueueServiceClient.socket = io(baseURL, socketParams);

      QueueServiceClient.socket.on('connect', async () => {
        console.info('%c SOCKET successfully connected!', 'color: green;');
        putInStorage('thirdPartyEnabled', true);

        try {
          if (sessionId) {
            const selectedConversation = await getSelectedConversation(); // Use await here
            const now = Date.now(); // Current time in milliseconds
            const twentyFourHoursAgo = now - (24 * 60 * 60 * 1000); // Time 24 hours ago in milliseconds

            if (selectedConversation) {
              store.dispatch(fetchArchivedMessages(selectedConversation, twentyFourHoursAgo, 'NEWER'));
            }

            sendSocketReconnectionEvent();
            QueueServiceClient.socket.emit('getMessages');
          }
        } catch (error) {
          console.error('Error in fetching selected conversation:', error);
        }
      });

      QueueServiceClient.socket.on('disconnect', (reason) => {
        console.info('SOCKET disconnected :', reason);
        if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
          if (sessionId) {
            QueueServiceClient.socket.connect();
          }
        }
      });

      QueueServiceClient.socket.on(email, (data) => {
        if (typeof data.data === 'string') {
          if (receiveIncoming) receiveIncoming(JSON.parse(data.data));
        } else if (receiveIncoming) receiveIncoming(data.data);
      });
      QueueServiceClient.socket.io.on('reconnect_attempt', () => {
        console.info('SOCKET reconnect_attempt');
      });

      QueueServiceClient.socket.io.on('reconnect', () => {
        console.info('SOCKET reconnect');
      });
      QueueServiceClient.socket.on('connect_error', (err) => {
        putInStorage('thirdPartyEnabled', false);
        console.error('SOCKET connect_error', JSON.stringify(err));
      });
      return QueueServiceClient.socket;
    }

    static GetPaginatedQueueMessages(args) {
      return RPC.rpcCall(
        '/queue.QueueService/GetPaginatedQueueMessages',
        args,
        queue.QueueResponseList,
        (request) => queue.QueueMessageInput.encode(request).finish(),
        queue.QueueResponseList.decode,
        {},
        true,
      );
    }
}
