import dayjs from 'dayjs';
import localforage from 'localforage';
import moment from 'moment';

const imageStore = localforage.createInstance({ storeName: 'imageStore' });

export const storeAuthData = (data) => {
  const myData = { ...data, stored_at: dayjs() };
  localStorage.setItem('LoggedState', JSON.stringify(myData));
};

export const storeOpenForm = (data) => {
  const myData = { ...data };
  localStorage.setItem('openForm', JSON.stringify(myData));
};

export const storeDomainSelected = (data) => {
  const myData = { ...data };
  localStorage.setItem('StoredDomain', JSON.stringify(myData));
};

export const storeLoginState = (data) => {
  localStorage.setItem('LoginState', data);
};

export const removeLoginState = (data) => {
  localStorage.removeItem('LoginState', data);
};

export const removeOpenForm = () => {
  localStorage.removeItem('openForm');
};

export const removeAuthData = () => {
  localStorage.removeItem('LoggedState');
};

export const removeDomainSelcted = () => {
  localStorage.removeItem('StoredDomain');
};

export const storeAuthCode = (authCode, email) => {
  localStorage.setItem('AuthCode', JSON.stringify({ [email]: authCode }));
};

export const setSignupPath = (pathName) => {
  localStorage.setItem('pathName', pathName);
};

export const setImageFile = (imgKey, imgFile) => {
  imageStore.setItem(imgKey, imgFile);
};

export const getImageFile = async (imgKey) => {
  try {
    const file = await imageStore.getItem(imgKey);
    return file;
  } catch (error) {
    return console.error('file not found', error);
  }
};

export const getStoredForm = () => {
  const openForm = localStorage.getItem('openForm');
  return openForm ? JSON.parse(openForm) : null;
};

export const getSignupPath = () => localStorage.getItem('pathName');

export const getAuthCode = () => {
  const authCode = localStorage.getItem('AuthCode');
  return authCode ? JSON.parse(authCode) : null;
};

export const unsetAuthCode = () => {
  localStorage.removeItem('AuthCode');
};

export const getAuthData = () => {
  const stored = localStorage.getItem('LoggedState');
  return stored ? JSON.parse(stored) : null;
};

export const getDomainSelcted = () => {
  const stored = localStorage.getItem('StoredDomain');
  return stored ? JSON.parse(stored) : null;
};

export const getLoginState = () => {
  const stored = localStorage.getItem('LoginState');
  return stored;
};

export const updateDataInStorage = async (key, value) => {
  const data = await getAuthData();
  if (data) {
    data[key] = value;
    await storeAuthData(data);
  }
};

export const storeUserDetails = (userId, user) => {
  localStorage.setItem(userId, JSON.stringify(user));
};

export const getStoredUserDetails = (userId) => {
  const stored = localStorage.getItem(userId);
  return stored ? JSON.parse(stored) : null;
};

export const putInStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromStorage = (key) => {
  const stored = localStorage.getItem(key);
  return stored ? JSON.parse(stored) : null;
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

export const removePathName = () => {
  localStorage.removeItem('pathName');
};

export const storeLinkData = (value) => {
  putInStorage('linkData', value);
};

export const getLinkData = () => getFromStorage('linkData');

export const removeLinkData = () => {
  removeFromStorage('linkData');
};

export const storeFormInLocal = (data) => {
  putInStorage('storeForm', data);
};

export const getStoreFormFromLocal = () => getFromStorage('storeForm');

export const removeStoreFormFromLocal = () => {
  removeFromStorage('storeForm');
};

export const storeSelectedConversation = async (value) => {
  try {
    await localforage.setItem('SelectedConversation', value);
    console.log('Selected conversation stored successfully.');
  } catch (error) {
    console.error('Error storing selected conversation:', error);
  }
};

export const getSelectedConversation = async () => {
  try {
    const selectedConversation = await localforage.getItem('SelectedConversation');
    return selectedConversation;
  } catch (error) {
    console.error('Error retrieving selected conversation:', error);
    return null;
  }
};

export const removeSelectedConversation = async () => {
  try {
    await localforage.removeItem('SelectedConversation');
    console.log('Selected conversation removed successfully.');
  } catch (error) {
    console.error('Error removing selected conversation:', error);
  }
};

export const storeSelectedContact = (value) => {
  putInStorage('SelectedContact', value);
};

export const getSelectedContact = () => getFromStorage('SelectedContact');

export const removeSelectedContact = () => {
  removeFromStorage('SelectedContact');
};

export const setUserOnline = (status) => {
  localStorage.setItem('ONLINE', status);
};

export const isUserOnline = () => {
  const status = localStorage.getItem('ONLINE');
  return status === null ? true : status === 'true';
};

export const setUserManualOnline = (status) => {
  localStorage.setItem('MANUAL_ONLINE', status);
};

export const isUserManualOnline = () => {
  const status = localStorage.getItem('MANUAL_ONLINE');
  return status === null ? true : status === 'true';
};

export const setUserMessagePoolTimestamp = (timestamp = moment().subtract(24, 'hours').valueOf()) => {
  localStorage.setItem('MSG_POOLING_TIMESTAMP', timestamp);
};

export const getUserMessagePoolTimestamp = () => {
  const timestamp = localStorage.getItem('MSG_POOLING_TIMESTAMP');
  if (['null', null, undefined].includes(timestamp)) {
    let now = moment().subtract(2, 'weeks').calendar();
    now = moment(now).valueOf();
    return now;
  }
  return parseInt(timestamp, 10);
};

export const updateAuthData = () => {
  const authData = getAuthData();
  if (authData && authData.auth) {
    authData.auth.newUser = false;
  }
  const myData = { ...authData, stored_at: dayjs() };
  localStorage.setItem('LoggedState', JSON.stringify(myData));
};

export const clearImgStore = () => {
  imageStore.clear();
};
